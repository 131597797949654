import React, { useState, useEffect, useContext } from "react";
import './Search.css';
import "../GlobalStyles.css";
import DataTableCluster from './DataTableCluster.js';
import axios from 'axios';
import GridLoader from 'react-spinners/GridLoader';
import SideBar from './SideBar.js';


export function getDatePublished(utcDateString) {
    const utcDate = new Date(utcDateString);
    return `${utcDate.getMonth() + 1}`.padStart(2, '0') +
        `/${utcDate.getDate()}`.padStart(2, '0') +
        `/${utcDate.getFullYear()}`;
}

export function getTimePublished(utcDateString) {
    const utcDate = new Date(utcDateString);
    let hours = utcDate.getHours();
    let minutes = utcDate.getMinutes();

    // Round minutes to the nearest 10, and adjust hours if necessary
    minutes = Math.ceil(minutes / 10) * 10;
    if (minutes === 60) {
        minutes = 0;
        hours++;
    }

    // If hours reach 24, set it back to 23 and minutes to 50
    if (hours === 24) {
        hours = 23;
        minutes = 50;
    }

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}


function SearchableCluster({ baseRequest, preset_query = "", selectedSortType = "Last Updated", showSideBar = true, 
    fringeCluster = false, turkeyCluster = false, chinaCluster = false }) {

    // Insert code to make initial call to ElasticSearch
    // Make request to Flask API to store the query; that's passed to Flask app which creates and calls the query

    const [tableData, setTableData] = useState([]); //tableData will always be displayed in the data table; setTableData() needs to be called by handleSearchClick
    const [isLoading, setIsLoading] = useState(true);
    const [noResults, setNoResults] = useState(false);

    // Initialize selected sort type based on prop
    useEffect(() => {
        // Map string selectedSortType to object if provided
        if (selectedSortType) {
            const sortOption = clusterSortOptions.find(option => option.label === selectedSortType);
            if (sortOption) {
                setselectedClusterSort(sortOption);
            }
        }
    }, [selectedSortType]);

    const [numResults, setNumResults] = useState(10);
    const [hasMoreResults, setHasMoreResults] = useState(true);


    const handleLoadMore = async () => {
        const newNumResults = numResults + 10;
        setNumResults(newNumResults);
        await fetchData(newNumResults);
    };


    useEffect(() => {
        fetchData();
    }, []); // This will call fetchData on component mount

    useEffect(() => {
        setIsLoading(tableData.length === 0);
    }, [tableData]);

    //Semantic Search
    const [selectedSemanticSearch, setSemanticSearch] = useState('');
    const handleSemanticSearchChange = (e) => {
        const searchValue = e.target.value;
        setSemanticSearch(searchValue);

        // Update the 'query' field of filters
        updateFilters({ query: searchValue });
    };

    // Search by Country
    const countryOptions = [
        "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina", "Armenia",
        "Australia", "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus",
        "Belgium", "Belize", "Benin", "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana", "Brazil",
        "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cabo Verde", "Cambodia", "Cameroon", "Canada",
        "Central African Republic", "Chad", "Chile", "China", "Colombia", "Comoros", "Congo (Brazzaville)",
        "Congo (Kinshasa)", "Costa Rica", "Croatia", "Cuba", "Cyprus", "Czech Republic", "Denmark",
        "Djibouti", "Dominica", "Dominican Republic", "Timor-Leste", "Ecuador", "Egypt", "El Salvador",
        "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini", "Ethiopia", "European Union", "Fiji", "Finland", "France",
        "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala",
        "Guinea", "Guinea-Bissau", "Guyana", "Haiti", "Honduras", "Hungary", "Iceland", "India",
        "Indonesia", "Iran", "Iraq", "Ireland", "Israel", "Italy", "Côte d'Ivoire", "Jamaica",
        "Japan", "Jordan", "Kazakhstan", "Kenya", "Kiribati", "North Korea", "South Korea", "Kosovo",
        "Kuwait", "Kyrgyzstan", "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya",
        "Liechtenstein", "Lithuania", "Luxembourg", "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali",
        "Malta", "Marshall Islands", "Mauritania", "Mauritius", "Mexico", "Micronesia", "Moldova", "Monaco",
        "Mongolia", "Montenegro", "Morocco", "Mozambique", "Myanmar", "Namibia", "Nauru", "Nepal",
        "Netherlands", "New Zealand", "Nicaragua", "Niger", "Nigeria", "North Macedonia", "Norway",
        "Oman", "Pakistan", "Palau", "Palestine", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines",
        "Poland", "Portugal", "Qatar", "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis", "Saint Lucia",
        "Saint Vincent and the Grenadines", "Samoa", "San Marino", "Sao Tome and Principe", "Saudi Arabia", "Senegal",
        "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands", "Somalia",
        "South Africa", "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname", "Sweden", "Switzerland",
        "Syria", "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Togo", "Tonga", "Trinidad and Tobago",
        "Tunisia", "Turkey", "Turkmenistan", "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom",
        "United States", "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City", "Venezuela", "Vietnam",
        "Yemen", "Zambia", "Zimbabwe"
    ].map(country => ({ label: country, value: country }));


    const [selectedCountries, setselectedCountries] = useState(null);

    const handleCountryChange = selectedOption => {
        setselectedCountries(selectedOption);
        updateFilters({ countries: selectedOption.map(option => option.value) });
    };

    // Search by Topic
    const [selectedTopics, setselectedTopics] = useState(null);
    const handleTopicChange = selectedOption => {
        setselectedTopics(selectedOption);
        updateFilters({ topics: selectedOption.map(option => option.value) });
    };
    const topicOptions = [
        "Arts, Culture, Entertainment and Media",
        "Conflict, War and Peace",
        "Crime, Law and Justice",
        "Disaster, Accident and Emergency Incident",
        "Economy, Business and Finance",
        "Education",
        "Environment",
        "Health",
        "Human Interest",
        "Labour",
        "Lifestyle and Leisure",
        "Politics",
        "Religion",
        "Science and Technology",
        "Society",
        "Sport",
        "Weather"
    ].sort().map(topic => ({ label: topic, value: topic }));


    const timeRangeOptions = [
        { label: "Past 24 Hours", value: "Past 24 Hours" },
        { label: "Past Week", value: "Past Week" },
        { label: "Past Month", value: "Past Month" },
        { label: "Past 4 Months", value: "Past 4 Months" },
    ];

    function formatDateTime(date) {
        const pad = (n) => n.toString().padStart(2, '0');
        return `${date.getUTCFullYear()}-${pad(date.getUTCMonth() + 1)}-${pad(date.getUTCDate())} ${pad(date.getUTCHours())}:${pad(date.getUTCMinutes())}:${pad(date.getUTCSeconds())}`;
    }

    const [startDate, setStartDate] = useState(null);


    const [selectedTimeRange, setSelectedTimeRange] = useState(timeRangeOptions.find(option => option.value === "Past 24 Hours"));


    const updateFiltersWithDates = (startDate, endDate) => {
        const newFilters = { ...filters, update_timestamp: [startDate, endDate] };
        setFilters(newFilters);
        // If you're fetching data right after updating filters, consider calling fetchData here
        fetchData(newFilters);
    };

    const now = new Date();

    const handleTimeRangeChange = selectedOption => {
        setSelectedTimeRange(selectedOption);
        const now = new Date();
        let startDate = now; // Fallback to current date
        let endDate = new Date(now.setDate(now.getDate() + 1)); // Tomorrow's date as the end date

        switch (selectedOption.value) {
            case "Past 24 Hours":
                startDate = new Date(new Date().setDate(now.getDate() - 1));
                break;
            case "Past Week":
                startDate = new Date(new Date().setDate(now.getDate() - 7));
                break;
            case "Past Month":
                startDate = new Date(new Date().setMonth(now.getMonth() - 1));
                break;
            case "Past 4 Months":
                startDate = new Date('2024-01-01T00:00:00');
                break;
            default:
                break;
        }

        setStartDate(startDate);
        updateFiltersWithDates(formatDateTime(startDate), formatDateTime(now));
    };


    // Sort By
    const clusterSortOptions = [
        { label: "Last Updated", value: "Last Updated" },
        { label: "Top Stories", value: "Top Stories" },
        { label: "Relevance", value: "Relevance" }
    ];
    const [selectedClusterSort, setselectedClusterSort] = useState(clusterSortOptions.find(option => option.value === "Relevance"));

    // Handle change in selected sort type
    const handleClusterSortChange = selectedOption => {
        setselectedClusterSort(selectedOption);
        // Update filters based on the selected sort option
        if (selectedOption && selectedOption.value === "Top Stories") {
            updateFilters({ get_biggest: true });
            updateFilters({ get_last_update: false });
            updateFilters({ page_rank: false })
        }
        else if (selectedOption && selectedOption.value === "Last Updated") {
            updateFilters({ get_biggest: false });
            updateFilters({ get_last_update: true });
            updateFilters({ page_rank: false })
        }
        else if (selectedOption && selectedOption.value === "Relevance") {
            updateFilters({ get_biggest: false });
            updateFilters({ get_last_update: false });
            updateFilters({ page_rank: true })
        }
        else {
            const { get_biggest, ...restFilters } = filters;
            setFilters(restFilters); // Remove get_biggest from filters
        }
    };

    const defaultFilters = {
        query: "",
        sources: [],
        countries: [],
        topics: [],
        keywords: [],
        update_timestamp: [formatDateTime(new Date(new Date().setDate(now.getDate() - 2))), formatDateTime(now)],
        links: [],
        tracker_type: [],
        cluster: true,
        set_size: numResults,
        get_biggest: selectedSortType === "Top Stories",
        get_last_update: selectedSortType === "Last Updated",
        page_rank: selectedSortType === "Relevance"
    };

    // Merge baseRequest with defaultFilters
    const initialFilters = { ...defaultFilters, ...baseRequest };

    // Use initialFilters to initialize the filters state
    const [filters, setFilters] = useState(initialFilters);

    const handleSearchClick = async () => {
        // Reset numResults to its default value
        setNumResults(10);

        // Reset isLoading and noResults state
        setIsLoading(true);
        setNoResults(false);

        try {
            await fetchData(10); // Pass 10 as the initial number of results to fetch
        } catch (error) {
            console.error("Error during API call:", error);
        }
    };


    // const handleSearchClick = async () => {
    //     // Reset numResults to its default value
    //     setNumResults(10);

    //     // Reset isLoading and noResults state
    //     setIsLoading(true);
    //     setNoResults(false);

    //     try {
    //         fetchData(0); // Call fetchData without any additional results
    //     } catch (error) {
    //         // Handle any errors
    //         console.error("Error during API call:", error);
    //     }
    // };


    const handleRefreshClick = async () => {
        const activeFilters = { ...filters };

        try {
            setIsLoading(true);
            fetchData();

        } catch (error) {
            // Handle any errors
            console.error("Error during API call:", error);
        }
    };


    // Update filters method
    const updateFilters = (newFilters) => {
        setFilters(prevFilters => ({ ...prevFilters, ...newFilters }));
    };

    const fetchData = async (resultsToFetch = numResults) => {
        try {
            // Combine preset_query with the user's query if preset_query is provided
            let queryParts = [];

            if (preset_query) {
                queryParts.push(preset_query);
            }

            if (filters.query) {
                queryParts.push(filters.query);
            }

            let combinedQuery = queryParts.join(" ");

            const updatedFilters = { ...filters, query: combinedQuery, set_size: resultsToFetch };

            // console.log("L326 filters are", updatedFilters);

            const response = await axios.post('https://fopotracking.ue.r.appspot.com/search', updatedFilters);
            // const response = await axios.post('http://localhost:5000/search', updatedFilters);

            // console.log("response is", response);

            let records = [];

            response['data'].forEach(item => {
                const {
                    cluster_size,
                    article_ids,
                    cluster_title,      // Correct field names based on the structure
                    cluster_summary,
                    creation_timestamp,
                    update_timestamp,
                    persons,
                    topics,
                    countries,
                    image_url,
                    article_embeddings_reduced
                } = item['source'];      // Access the data inside `item['source']`

                const uniqueId = item['id']; // Cluster ID is inside the main item object

                // Ensure `update_timestamp` exists
                let date = update_timestamp ? new Date(update_timestamp) : null;

                // Push the parsed record to the records array
                records.push({
                    "cluster_id": uniqueId,
                    "cluster_size": cluster_size,
                    "article_ids": article_ids,
                    "current_title": cluster_title,         // Correct field names
                    "current_summary": cluster_summary,     // Correct field names
                    "creation_timestamp_utc": creation_timestamp.replace(" ", "T") + "+00:00",
                    "update_timestamp_utc": update_timestamp.replace(" ", "T") + "+00:00",
                    "persons": persons,
                    "topics": topics,
                    "countries": countries,
                    "image_link": image_url,
                    "article_embeddings_reduced": article_embeddings_reduced
                });
            });

            // console.log("Parsed records: ", records);

            setTableData(records);

            if (records.length === 0) {
                setNoResults(true);
            } else {
                setNoResults(false);
            }
            // console.log("records are", records);
            if (records.length < resultsToFetch) {
                setHasMoreResults(false); // No more records to fetch
            } else {
                setHasMoreResults(true); // More records available
            }
        } catch (error) {
            console.error('Error fetching latest data:', error);
            setHasMoreResults(false); // Assume no more records on error
        }
    };


    const sidebar = (
        <div>
            {showSideBar && (
                <div className="sidebar">
                    <SideBar
                        clusterSearch={true}
                        selectedSemanticSearch={selectedSemanticSearch}
                        onSemanticSearchChange={handleSemanticSearchChange}

                        countryOptions={countryOptions}
                        selectedCountries={selectedCountries}
                        onCountryChange={handleCountryChange}

                        topicOptions={topicOptions}
                        selectedTopics={selectedTopics}
                        onTopicChange={handleTopicChange}

                        clusterSortOptions={clusterSortOptions}
                        selectedClusterSort={selectedClusterSort}
                        onClusterSortChange={handleClusterSortChange}

                        timeRangeOptions={timeRangeOptions}
                        selectedTimeRange={selectedTimeRange}
                        onTimeRangeChange={handleTimeRangeChange}

                        handleSearchClick={handleSearchClick}
                        handleRefreshClick={handleRefreshClick}
                    />
                </div>
            )}
        </div>
    );

    // Conditionally render DataTable content based on isLoading
    let dataTableContent;

    if (!noResults && isLoading) {
        dataTableContent = (
            <div className="data-table" style={{ textAlign: "center" }}>
                <br /><br />
                <GridLoader />
            </div>
        );
    }
    else if (noResults && isLoading) {
        dataTableContent = (
            <div className="data-table" style={{ textAlign: "center" }}>
                <br />
                <h2>No results found.</h2>
            </div>
        );
    }
    else {
        dataTableContent = (
            <div className="data-table">
                <div>
                    <DataTableCluster
                        data={tableData}
                        fringeCluster={fringeCluster}
                        chinaCluster={chinaCluster}
                        turkeyCluster={turkeyCluster}
                    />
                </div>
                <div style={{ textAlign: 'center', marginTop: '1rem' }}>
                    {hasMoreResults ? (
                        <button className="btn-custom btn-charcoal-gray" onClick={handleLoadMore}>Load More</button>
                    ) : (
                        <h4>Showing all relevant results.</h4>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div className="searchable-container">
            {sidebar}
            {dataTableContent}
        </div>
    );

};

export default SearchableCluster;
