import '../GlobalStyles.css'; // Import the CSS file
import React, { useState, useEffect, useRef } from "react";
import "../GlobalStyles.css";
import { useUser } from "../../contexts/UserContext.js";
import axios from 'axios';
import DataTableCluster from '../search/DataTableCluster.js';
import CustomBadge from '../fixtures/CustomBadge.js';
import GridLoader from 'react-spinners/GridLoader.js';
import { Link } from 'react-router-dom';
import Alert from "react-bootstrap/Alert";


// Predefined region lists
export const unitedStates = ["United States"];
export const middleEastAndNorthAfrica = ["Algeria", "Bahrain", "Egypt", "Iran", "Iraq", "Israel", "Jordan", "Kuwait", "Lebanon", "Libya", "Morocco", "Oman", "Palestine", "Qatar", "Saudi Arabia", "Syria", "Tunisia", "Turkey", "United Arab Emirates", "Yemen"];
export const pacificRim = ["Australia", "Brunei", "China", "Indonesia", "Japan", "Malaysia", "New Zealand", "Papua New Guinea", "Philippines", "Singapore", "South Korea", "Taiwan", "Thailand", "Vietnam", "Fiji", "Kiribati", "Marshall Islands", "Micronesia", "Nauru", "Palau", "Samoa", "Solomon Islands", "Tonga", "Tuvalu", "Vanuatu"];
export const asia = ["Afghanistan", "Armenia", "Azerbaijan", "Bangladesh", "Bhutan", "Cambodia", "Georgia", "India", "Kazakhstan", "Kyrgyzstan", "Laos", "Maldives", "Mongolia", "Myanmar", "Nepal", "North Korea", "Pakistan", "Sri Lanka", "Tajikistan", "Turkmenistan", "Uzbekistan"];
export const europe = ["Albania", "Andorra", "Austria", "Belarus", "Belgium", "Bosnia", "Bulgaria", "Croatia", "Cyprus", "Czech Republic", "Denmark", "Estonia", "Finland", "France", "Germany", "Greece", "Hungary", "Iceland", "Ireland", "Italy", "Kosovo", "Latvia", "Liechtenstein", "Lithuania", "Luxembourg", "Malta", "Moldova", "Monaco", "Montenegro", "Netherlands", "North Macedonia", "Norway", "Poland", "Portugal", "Romania", "Russia", "San Marino", "Serbia", "Slovakia", "Slovenia", "Spain", "Sweden", "Switzerland", "Ukraine", "United Kingdom", "Vatican City"];
export const americas = ["Antigua and Barbuda", "Argentina", "Bahamas", "Barbados", "Belize", "Bolivia", "Brazil", "Canada", "Chile", "Colombia", "Costa Rica", "Cuba", "Dominica", "Dominican Republic", "Ecuador", "El Salvador", "Grenada", "Guatemala", "Guyana", "Haiti", "Honduras", "Jamaica", "Mexico", "Nicaragua", "Panama", "Paraguay", "Peru", "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent and the Grenadines", "Suriname", "Trinidad and Tobago", "Uruguay", "Venezuela"];
export const subSaharanAfrica = ["Angola", "Benin", "Botswana", "Burkina Faso", "Burundi", "Cabo Verde", "Cameroon", "Central African Republic", "Chad", "Comoros", "Congo (Brazzaville)", "Congo (Kinshasa)", "Djibouti", "Equatorial Guinea", "Eritrea", "Eswatini", "Ethiopia", "Gabon", "Gambia", "Ghana", "Guinea", "Guinea-Bissau", "Ivory Coast", "Kenya", "Lesotho", "Liberia", "Madagascar", "Malawi", "Mali", "Mauritania", "Mauritius", "Mozambique", "Namibia", "Niger", "Nigeria", "Rwanda", "Sao Tome and Principe", "Senegal", "Seychelles", "Sierra Leone", "Somalia", "South Africa", "South Sudan", "Sudan", "Tanzania", "Togo", "Uganda", "Zambia", "Zimbabwe"];

const GlobalPulse = () => {
    const { user } = useUser();

    const [tableDataUS, setTableDataUS] = useState(null);
    const [tableDataME, setTableDataME] = useState(null);
    const [tableDataAM, setTableDataAM] = useState(null);
    const [tableDataAF, setTableDataAF] = useState(null);
    const [tableDataPR, setTableDataPR] = useState(null);
    const [tableDataAS, setTableDataAS] = useState(null);
    const [tableDataEU, setTableDataEU] = useState(null);

    const [isLoading, setIsLoading] = useState(true);
    const [noResults, setNoResults] = useState(false);

    const uniqueIds = useRef(new Set());
    const fetchStatus = useRef({
        "United States of America": false,
        "Middle East": false,
        "Pacific Rim": false,
        "Asia": false,
        "Europe": false,
        "Americas": false,
        "Sub-Saharan Africa": false
    });

    useEffect(() => {
        const isLoading =
            tableDataUS === null ||
            tableDataME === null ||
            tableDataAM === null ||
            tableDataAF === null ||
            tableDataPR === null ||
            tableDataAS === null ||
            tableDataEU === null;

        setIsLoading(isLoading);
    }, [tableDataUS, tableDataME, tableDataAM, tableDataAF, tableDataPR, tableDataAS, tableDataEU]);

    useEffect(() => {
        const regions = [
            { name: "United States of America", setTableData: setTableDataUS, countries: unitedStates },
            { name: "Middle East", setTableData: setTableDataME, countries: middleEastAndNorthAfrica },
            { name: "Pacific Rim", setTableData: setTableDataPR, countries: pacificRim },
            { name: "Asia", setTableData: setTableDataAS, countries: asia },
            { name: "Europe", setTableData: setTableDataEU, countries: europe },
            { name: "Americas", setTableData: setTableDataAM, countries: americas },
            { name: "Sub-Saharan Africa", setTableData: setTableDataAF, countries: subSaharanAfrica }
        ];

        regions.forEach(region => {
            if (!fetchStatus.current[region.name]) {
                fetchData(region.countries, region.setTableData);
                fetchStatus.current[region.name] = true;
            }
        });
    }, []); // This will call fetchData on component mount

    function formatDateTime(date) {
        const pad = (n) => n.toString().padStart(2, '0');
        return `${date.getUTCFullYear()}-${pad(date.getUTCMonth() + 1)}-${pad(date.getUTCDate())} ${pad(date.getUTCHours())}:${pad(date.getUTCMinutes())}:${pad(date.getUTCSeconds())}`;
    }

    const now = new Date();
    const threeHoursAgo = new Date(now.getTime() - (3 * 60 * 60 * 1000));
    const sixHoursAgo = new Date(now.getTime() - (6 * 60 * 60 * 1000));
    const twelveHoursAgo = new Date(now.getTime() - (12 * 60 * 60 * 1000));
    const twentyFourHoursAgo = new Date(now.getTime() - (24 * 60 * 60 * 1000));
    const fortyEightHoursAgo = new Date(now.getTime() - (48 * 60 * 60 * 1000));
    const oneWeekAgo = new Date(now.getTime() - (168 * 60 * 60 * 1000));



    function getCurrentFormattedDate() {
        const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        const currentDate = new Date();
        const month = months[currentDate.getMonth()];
        const day = currentDate.getDate();
        const year = currentDate.getFullYear();

        const hours = currentDate.getHours();
        const minutes = currentDate.getMinutes().toString().padStart(2, '0');
        const time = `${hours}:${minutes}`;

        // const timezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const timezoneName = new Intl.DateTimeFormat('en-US', { timeZoneName: 'short' }).format(new Date()).split(', ')[1]

        return `${month} ${day}, ${year} • ${time} ${timezoneName}`;
    }

    const filters = {
        query: "",
        sources: [],
        countries: [],
        topics: [],
        keywords: [],
        links: [],
        tracker_type: [],
        cluster: true,
        get_biggest: true,
        get_last_update: false,
        topics: [
            "Conflict, War and Peace",
            "Crime, Law and Justice",
            "Disaster, Accident and Emergency Incident",
            "Environment",
            "Health",
            "Politics",
            "Religion",
            "Science and Technology",
            "Society",
        ],
        // sort_by_last_6: true,
        // hot: true,
        // Creation_timestamp: [formatDateTime(oneWeekAgo), formatDateTime(now)],
        update_timestamp: [formatDateTime(threeHoursAgo), formatDateTime(now)],
    };

    const fetchData = async (countries, setTableData) => {
        try {
            // Update the filters object with the countries list
            const updatedFilters = { ...filters, countries };
            // console.log("filters are", updatedFilters);
            const response = await axios.post('https://fopotracking.ue.r.appspot.com/search', updatedFilters);
            // const response = await axios.post('http://localhost:5000/search', updatedFilters);
            let records = [];

            response.data.forEach(item => {
                const uniqueId = item.id; // Adjust this according to your response structure
                // console.log("item is", item);
                // Check if the uniqueId is already present
                if (!uniqueIds.current.has(uniqueId)) {
                    uniqueIds.current.add(uniqueId);
                    records.push({
                        "cluster_id": item.id,
                        "current_title": item.source['cluster_title'].replace(/\*/g, ''), // Removes all asterisks
                        "current_summary": item.source['cluster_summary'].replace(/\*/g, ''), // Removes all asterisks
                        "creation_timestamp_utc": item.source['creation_timestamp'].replace(" ", "T") + "+00:00",
                        "update_timestamp_utc": item.source['update_timestamp'].replace(" ", "T") + "+00:00",
                        "article_ids": item.source['article_ids'],
                        "cluster_size": item.source['cluster_size'],
                        "image_link": item.source['image_url'] || null,
                        "countries": item.source['countries'],
                        "last_3": item.source['last_3']
                        // "title_history": item['source']['Title history'],
                        // "summary_history": item['source']['Summary history']
                    });
                }
            });

            records = records.slice(0, 3);

            setTableData(records);
            if (records.length === 0) {
                setNoResults(true);
            } else {
                setNoResults(false);
            }
            // console.log("records are", records);
        } catch (error) {
            // console.error('Error fetching latest data:', error);
        }
    };

    return (
        <div>
            <h1 style={{ textAlign: 'center' }}>VerbaAI Global Pulse</h1>
            <h3 style={{ textAlign: 'center' }}>{getCurrentFormattedDate()}</h3>
            {/* <Alert style={{ backgroundColor: '#222222', color: '#F8F9FA', padding: '10px 20px' }} className="text-center">
                We're making a few changes to Global Pulse to get you the most up to date stories from around the world. We'll be back soon!
            </Alert> */}
            <div style={{ height: '0.75rem' }}></div>
            {isLoading && (
                <div style={{ textAlign: "center" }}>
                    <br /><br />
                    <GridLoader />
                    <br />
                    <strong><h4>Analyzing world news...</h4></strong>
                </div>
            )}
            {!isLoading && (
                <div>
                    <Link to="/region/united_states">
                        <CustomBadge text={<h3 style={{ marginBottom: '0' }}>United States</h3>} backgroundColor='#222222' />
                    </Link>
                    <DataTableCluster data={tableDataUS} showIndex={false} />

                    <Link to="/region/mena">
                        <CustomBadge text={<h3 style={{ marginBottom: '0' }}>Middle East and North Africa</h3>} backgroundColor='#222222' />
                    </Link>
                    <DataTableCluster data={tableDataME} showIndex={false} />

                    <Link to="/region/pacific_rim">
                        <CustomBadge text={<h3 style={{ marginBottom: '0' }}>Pacific Rim</h3>} backgroundColor='#222222' />
                    </Link>
                    <DataTableCluster data={tableDataPR} showIndex={false} />

                    <Link to="/region/asia">
                        <CustomBadge text={<h3 style={{ marginBottom: '0' }}>Asia</h3>} backgroundColor='#222222' />
                    </Link>
                    <DataTableCluster data={tableDataAS} showIndex={false} />

                    <Link to="/region/europe">
                        <CustomBadge text={<h3 style={{ marginBottom: '0' }}>Europe</h3>} backgroundColor='#222222' />
                    </Link>
                    <DataTableCluster data={tableDataEU} showIndex={false} />

                    <Link to="/region/americas">
                        <CustomBadge text={<h3 style={{ marginBottom: '0' }}>Americas</h3>} backgroundColor='#222222' />
                    </Link>
                    <DataTableCluster data={tableDataAM} showIndex={false} />

                    <Link to="/region/sub_saharan_africa">
                        <CustomBadge text={<h3 style={{ marginBottom: '0' }}>Sub-Saharan Africa</h3>} backgroundColor='#222222' />
                    </Link>
                    <DataTableCluster data={tableDataAF} showIndex={false} />
                </div>
            )}
        </div>
    );
};

export default GlobalPulse;
